import React, {useRef, useEffect} from 'react'
import {Link, navigate} from 'gatsby'
import gsap from "gsap"
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import "../styles/projectStyles.scss"

import {
    useGlobalDispatchContext,
  } from '../context/globalContext'
import {NextProject, RightArrow} from '../assets/text'
  

gsap.registerPlugin(ScrollTrigger)


const HairSalon = () => {

    const dispatch = useGlobalDispatchContext()

  
    const onCursor = cursorType => {
        dispatch({ type: "CURSOR_TYPE", cursorType: cursorType })
    }

//refs
const overlay_top = useRef(null)
const overlay_bottom = useRef(null)
const line1 = useRef(null)
const line2 = useRef(null)
const line3 = useRef(null)
const line4 = useRef(null)
const line5 = useRef(null)
const line6 = useRef(null)
const line7 = useRef(null)
const line8 = useRef(null)
const line9 = useRef(null)
const line10 = useRef(null)
const line11 = useRef(null)
const line12 = useRef(null)
const line13 = useRef(null)
const line14 = useRef(null)
const line15 = useRef(null)
const line16 = useRef(null)
const line17 = useRef(null)
const imageOverlay1 = useRef(null)
const imageOverlay2 = useRef(null)
const imageOverlay3 = useRef(null)


//intro
useEffect(() => {


    const tl = gsap.timeline()
    tl.to([overlay_top.current, overlay_bottom.current], {
        duration:1.3,
        height:0,
        delay: .7,
        ease:"Power3.easeOut",
        stagger:{
            amount:.4
        }
    })

    const pTl = gsap.timeline()
    pTl.from(line1.current,{
        delay:2.2,
        duration:1,
        opacity:0,
        ease:'Power3.easeOut'
    })
    .from([ line2.current,line3.current],{
        delay:0,
        duration:.6,
        opacity:0,
        y:0,
        stagger:.1,
        ease:'Power3.easeOut'
    })
     .from([line4.current, line5.current, line6.current, line7.current, line8.current, line9.current, line10.current, line11.current, line12.current, line13.current], {
        skewY:10,
        duration:1,
        y:120,
        stagger:.15,
        ease:"Power3.easeOut"
    }) 
    .from('.visit', {
        duration:.4,
        opacity:0,
        ease:"Power3.easeOut"
    })
    onCursor('')
}, [])


const handleContact = (e) => {
    e.preventDefault()
    gsap.to('.project-container',{
        duration: 1,
        opacity:0,
        y:-200
    })
    setTimeout(()=>{
        navigate('/contact')
    }, 1300)
}

const handleHome = (e) => {
    e.preventDefault()
    gsap.to('.project-container',{
        duration: 1,
        opacity:0,
        y:-100
    })
    setTimeout(()=>{
        navigate('/')
    }, 1000)
}
const handleWork = (e) => {
    e.preventDefault()
    gsap.to('.project-container',{
        duration: 1,
        opacity:0,
        y:-100
    })
    setTimeout(()=>{
        navigate('/work')
    }, 1000)
}

const handleMouseEnter = () => {
    gsap.to('.arrows svg', {
        duration:.3,
        x:40,
        stagger:.1
    })
}
const handleMouseOut = () => {
    gsap.to('.arrows svg',{
        duration:.3,
        x:0,
        stagger:.1
    })
}


useEffect(()=>{
    gsap.to(imageOverlay1.current,{
        duration:1.2,
        height:0,
        ease:'Power3.easeOut',
        scrollTrigger:{
            trigger: '.project-summary',
            start:'bottom top'
        }
    }) 
    gsap.to(imageOverlay2.current,{
        duration:1.2,
        height:0,
        ease:'Power3.easeOut',
        scrollTrigger:{
            trigger: '.showcase-description',
            start:'bottom top'
        }
    })
    gsap.to(imageOverlay3.current,{
        duration:1.2,
        height:0,
        ease:'Power3.easeOut',
        scrollTrigger:{
            trigger: '.second',
            start:'bottom top',
        }
    })
  
  gsap.from([line14.current, line15.current, line16.current, line17.current], {
      duration:1.2,
      delay:.3,
      y:150,
      skewY:10,
      stagger:.2,
      ease:"Power3.easeOut",
      scrollTrigger:{
          trigger:'.img1',
          start:'bottom center',
       /*    markers:true */
      }
  })
  gsap.from('.schowcase-image-wrapper img',{
      duration:.8,
      delay:.9,
      x:60,
      opacity:0,
      ease:"Power3.easeOut",
      scrollTrigger:{
        trigger:'.img1',
        start:'bottom center',
        /* markers:true */
    }
  })   
},[])








    return (
        <>
            <div className="project-overlay-bottom" ref={overlay_bottom}></div>
            <div className="project-overlay-top" ref={overlay_top}></div>
            <div className="project-container">
            <div className="navigation">
                    <Link to='/' onMouseEnter={()=>onCursor('pointer')} onMouseOut={()=>onCursor('')} onClick={handleHome}>
                        <p>Home</p>
                    </Link>
                    <Link to='/work' onMouseEnter={()=>onCursor('pointer')} onMouseOut={()=>onCursor('')} onClick={handleWork}>
                        <p>Work</p>
                    </Link>
                </div>
            
            <div className="project-banner">
                <div className="banner-wrapper">
                    <p ref={line1}>HairSalon</p>
                    <p ref={line2}>HairSalon</p>
                    <p ref={line3}>HairSalon</p>                    
                </div>
            </div>
            <div className="project-summary">
                <div className="project-summary-wrapper">
                    <div className="project-summary-left">
                        <div className="showcase-content">
                            <p ref={line4}>What's this?</p> 
                        </div>
                        <div className="showcase-content">
                            <h6 ref={line5}>A modern hair salon landing page</h6>
                        </div>
                        <div className="visit">
                            <a href="/">view website</a>
                        </div>
                    </div>
                    <div className="project-summary-right">
                        <div className="client">
                            <div className="showcase-content">
                                <p ref={line6}>Client</p>
                            </div>
                            <div className="showcase-content">
                                <h6 ref={line7}>Fantasy client</h6>
                            </div>
                        </div>
                        <div className="focus">
                        <div className="showcase-content banner">
                            <p ref={line8}>Focus</p>
                        </div>
                        <div className="showcase-content banner">
                            <h6 ref={line9}>Interaction Design</h6>
                        </div>
                        <div className="showcase-content banner">
                            <h6 ref={line10}>UI/UX</h6>
                        </div>
                        <div className="showcase-content banner">
                            <h6 ref={line11}>Page Development</h6>
                        </div>
                        <div className="showcase-content banner">
                            <h6 ref={line12}>SEO optimization</h6>
                        </div>
                        <div className="showcase-content banner">
                            <h6 ref={line13}>Content Creation</h6>
                        </div>
                     </div>
                    </div>
                </div>
            </div>
            <section className='showcase'>
                <div className="image-wrapper first">
                    <div className="image-overlay" ref={imageOverlay1}></div>
                    <img className='img1' src={require('../images/img1.jpg')} alt=""/>
                </div>
            </section>
            <section className='showcase-description'>
                <div className="showcase-description-wrapper">
                    <div className='showcase-content'>
                        <h1 ref={line14}>Built from scratch</h1>
                    </div>
                    <div className='showcase-content'>
                        <p ref={line15}>This was a project that started from scratch.</p>
                    </div>
                    <div className='showcase-content'> 
                        <p ref={line16}>From the name to the visual approach,</p>
                    </div>
                    <div className='showcase-content'>
                        <p ref={line17}>before dive into the UI/UX and code.</p>
                    </div>
                    
                </div>
                <div className="schowcase-image-wrapper">
                    <img src={require('../images/Iphone.png')} alt='iphone' />
                </div>
            </section>
            <section className='showcase'>
                <div className="image-wrapper second">
                    <div className="image-overlay" ref={imageOverlay2}></div>
                    <img src={require('../images/img1.jpg')} alt=""/>
                </div>
            </section>
            <section className='showcase'>
                <div className="image-wrapper second">
                    <div className="image-overlay" ref={imageOverlay3}></div>
                    <img src={require('../images/img1.jpg')} alt=""/>
                </div>
            </section>
             <section className='next-project' onMouseEnter={()=> {
                onCursor('pointer')
                handleMouseEnter()
            }} onMouseLeave={()=>{
                onCursor('')
                handleMouseOut()
            }}>
                 <NextProject/>
                <div className="arrows">
                    <RightArrow/>
                    <RightArrow/>
                    <RightArrow/>
                </div>
                   
            </section> 

      


            <section className='interested'>
                <h1>interested ?</h1>
                <Link to='/contact' onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=>onCursor()} onClick={handleContact}>
                    <div className="contact">
                        <p>contact <br/> me</p>
                    </div>
                </Link>
            </section>
            </div>
        </>
    )
}



export default HairSalon
